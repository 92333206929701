<template>
  <div id="nasiv-layout" class="h-full min-h-screen w-full">
    <div id="header">
      <div class="flex items-center justify-between">
        <div class="w-36">
          <img src="@/assets/images/nasiv-summit-logo.svg" alt="" />
        </div>

        <div
          id="navigation"
          class="space-x-3 lg:space-x-6 font-grold font-bold hidden lg:flex"
        >
          <div class="link">
            <router-link to="/nasarawasummit#speakers">Speakers</router-link>
          </div>
          <div class="link">
            <router-link to="/nasarawasummit#plenaries">Plenaries</router-link>
          </div>
          <div class="link">
            <router-link to="/nasarawasummit#deal-room">Deal Room</router-link>
          </div>
          <div class="link">
            <router-link to="/nasarawasummit#msme-challenge"
              >MSME Challenge</router-link
            >
          </div>
          <div class="relative">
            <div
              class="flex items-center space-x-2 cursor-pointer"
              @click="toggleResourceDropdown()"
            >
              <p>Resources</p>
              <Icon
                class="dropdown-icon"
                v-if="resourcesOpened"
                icon="akar-icons:chevron-up"
                height="18"
                width="18"
              />
              <Icon
                class="dropdown-icon"
                v-else
                icon="akar-icons:chevron-down"
                height="18"
                width="18"
              />
            </div>
            <ul
              id="resources"
              v-if="resourcesOpened"
              class="absolute top-8 z-50 right-0"
            >
              <li v-for="resource in resources" :key="resource.name">
                <a
                  :href="resource.link"
                  :download="resource.name"
                  target="_blank"
                  @click="toggleResourceDropdown()"
                >
                  {{ resource.name }}
                </a>
              </li>
            </ul>
          </div>
          <div class="link">
            <router-link to="/nasarawasummit#sponsors">Sponsors</router-link>
          </div>
        </div>

        <div @click="toggleNavigation()" class="flex md:hidden">
          <Icon
            icon="icon-park-outline:hamburger-button"
            height="28"
            width="28"
            v-if="!isNavOpen"
            key="closed"
          />
          <Icon icon="bi:x-lg" height="24" width="24" v-else key="opened" />
        </div>
      </div>

      <!-- Mobile Navigation -->
      <div
        class="mobile-navigation font-grold"
        :class="{ 'mt-0': isNavOpen, '-mt-96': !isNavOpen }"
      >
        <div @click="toggleNavigation()" class="flex md:hidden">
          <Icon
            icon="icon-park-outline:hamburger-button"
            height="28"
            width="28"
            v-if="!isNavOpen"
            key="closed"
          />
          <Icon icon="bi:x-lg" height="24" width="24" v-else key="opened" />
        </div>
        <router-link to="#speakers" @click="closeNavigation()"
          >Speakers</router-link
        >
        <router-link to="#plenaries" @click="closeNavigation()"
          >Plenaries</router-link
        >
        <router-link to="#deal-room" @click="closeNavigation()"
          >Deal Room</router-link
        >
        <router-link to="#msme-challenge" @click="closeNavigation()"
          >MSME Challenge</router-link
        >
        <Accordion title="Resources" :spaced="true" :closed="true">
          <li v-for="resource in resources" :key="resource.name">
            <a
              :href="resource.link"
              :download="resource.name"
              target="_blank"
              @click="closeNavigation()"
            >
              {{ resource.name }}
            </a>
          </li>
        </Accordion>
        <router-link to="#sponsors" @click="closeNavigation()"
          >Sponsors</router-link
        >
      </div>
    </div>
    <router-view />
    <footer id="footer">
      <div id="footer-container">
        <div class="grid lg:grid-cols-3 gap-y-8 lg:gap-4 font-grold">
          <div class="space-y-6">
            <div class="w-60">
              <img src="@/assets/images/nasiv-summit-logo.svg" alt="" />
            </div>
            <div class="flex space-x-4">
              <a href="https://facebook.com/Nas_investsummit-106754982013146/" target="_blank">
                <Icon icon="iconoir:facebook" height="24" width="24" />
              </a>
              <a href="https://instagram.com/nas_investsummit?igshid=YmMyMTA2M2Y=" target="_blank">
                <Icon icon="iconoir:instagram" height="24" width="24" />
              </a>
              <a href="https://twitter.com/nas_invest?s=21" target="_blank">
                <Icon icon="iconoir:twitter" height="24" width="24" />
              </a>
              <p>
                <span class="font-reset">@</span>nasida<span class="font-reset"
                  >-</span
                >invest
              </p>
            </div>
            <div class="text-2xl">
              <a href="mailto:info@nasida.na.gov.ng"
                >info<span class="font-reset">@</span>nasida.na.gov.ng</a
              >
            </div>
          </div>
          <div class="space-y-6">
            <h2 class="text-xl">Stay up to date</h2>
            <div class="flex bg-white w-full bg-opacity-25 py-3 px-4">
              <input
                type="text"
                class="
                  outline-none
                  w-full
                  bg-transparent
                  text-white
                  placeholder-white
                  font-thin
                "
                placeholder="Your email address"
              />
              <div class="cursor-pointer">
                <Icon icon="bi:send" height="24" width="24" />
              </div>
            </div>
            <div class="space-y-6">
              <div class="flex items-center space-x-4 font-sans">
                <Icon icon="la:mobile" height="24" width="24" />
                <a href="tel:2341234567890" class="text">+234-123-456-7890</a>
              </div>
              <div class="flex items-center space-x-4">
                <Icon icon="ci:location" height="24" width="24" />
                <p>Lafia, Nasarawa State</p>
              </div>
            </div>
          </div>
          <div class="space-y-6 flex flex-col lg:mx-auto">
            <router-link to="/nasarawasummit#speakers">Speakers</router-link>
            <router-link to="/nasarawasummit#plenaries">Plenaries</router-link>
            <router-link to="/nasarawasummit#deal-room">Deal Room</router-link>
            <router-link to="/nasarawasummit#msme-challenge"
              >SME Challenge</router-link
            >
            <router-link to="/nasarawasummit#">Resources</router-link>
            <router-link to="/nasarawasummit#sponsors">Sponsors</router-link>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script src="./nasiv.js"></script>
<style src="./nasiv.css" scoped lang="css"></style>
