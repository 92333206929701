import { defineComponent, ref } from "vue";
import Accordion from "../../components/accordion/index.vue";

export default defineComponent({
  name: "NASIVLayout",
  components: { Accordion },
  setup() {
    const isNavOpen = ref(false);
    const resourcesOpened = ref(false)
    const resources = [
      { name: 'The NIS Agenda', link: 'https://nasidainvestment-my.sharepoint.com/:b:/g/personal/summit_nasida_na_gov_ng/EXN-5TiuFxlMsDzj2Z7xU3kBE8KNF9P4D4chhXptbDwwpg?e=ZECmyc' },
      { name: 'The Deal Book', link: 'https://nasidainvestment-my.sharepoint.com/:b:/g/personal/summit_nasida_na_gov_ng/EaZc3h_n54hCrVEKcN2eNcQBIs7IuR6Gi0IEmDkiSWEWdA?e=AIrBQH' },
    ]

    return {
      isNavOpen,
      resources,
      resourcesOpened
    }
  },
  methods: {
    toggleNavigation() {
      this.isNavOpen = !this.isNavOpen;
    },
    toggleResourceDropdown() {
      this.resourcesOpened = !this.resourcesOpened;
    },
    closeNavigation() {
      this.isNavOpen = false;
    },
  },
});
